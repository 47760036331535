<template>
  <ez-form
    class="c--generate-form"
    :model="model"
    :rules="rules"
    ref="form"
    inline
  >
    <ez-form-item
      v-for="(item, index) in model.template"
      :prop="`template.${index}.value`"
      :key="item.labelEn"
      :label="getLabel(item, true) + ': '"
    >
      <!--    TEXT-->
      <template v-if="item.type === COMPONENT_TYPES.TEXT.VALUE">
        <div class="text-wrap" v-html="textToHtml(item.value)"></div>
      </template>

      <!--    INPUT-->
      <template v-if="item.type === COMPONENT_TYPES.INPUT.VALUE">
        <ez-input
          type="textarea"
          :autosize="{ minRows: 1, maxRows: 3 }"
          resize="none"
          v-model="item.value"
          :disabled="item.disabled"
          maxlength="500"
          style="width: 340px"
        />
      </template>

      <!--    SELECT-->
      <template v-if="item.type === COMPONENT_TYPES.SELECT.VALUE">
        <ez-select v-model="item.value" :disabled="item.disabled">
          <ez-option
            v-for="i in item.options"
            :label="getLabel(i, true)"
            :key="getLabel(i)"
            :value="getLabel(i)"
          />
        </ez-select>
        <ez-input
          type="textarea"
          :autosize="{ minRows: 1, maxRows: 3 }"
          maxlength="500"
          resize="none"
          style="margin-top: 10px;width: 340px"
          v-if="item.value === SYSTEM_OTHER"
          placeholder="Other"
          v-model="item.otherValue"
        />
      </template>

      <!--    RADIO-->
      <template v-if="item.type === COMPONENT_TYPES.RADIO.VALUE">
        <ez-radio-group class="is-line2" v-model="item.value" :disabled="item.disabled">
          <ez-radio
            v-for="i in item.options"
            :label="getLabel(i)"
            :key="getLabel(i)">
            <span v-html="replaceUrlToLink(getLabel(i, true))"></span>
          </ez-radio>
        </ez-radio-group>
        <ez-input
          type="textarea"
          :autosize="{ minRows: 1, maxRows: 3 }"
          resize="none"
          style="margin-top: 10px;width: 340px"
          maxlength="500"
          v-if="item.value === SYSTEM_OTHER"
          placeholder="Other"
          v-model="item.otherValue"
        />
      </template>

      <!--    CHECKBOX-->
      <template v-if="item.type === COMPONENT_TYPES.CHECKBOX.VALUE">
        <ez-checkbox-group class="is-line2" v-model="item.value" :disabled="item.disabled">
          <ez-checkbox
            v-for="i in item.options"
            :label="getLabel(i)"
            :key="getLabel(i)">
            <span v-html="replaceUrlToLink(getLabel(i, true))"></span>
          </ez-checkbox>
        </ez-checkbox-group>
      </template>

    </ez-form-item>
  </ez-form>
</template>

<script>
import { COMPONENT_TYPES } from '@/config'
const SYSTEM_OTHER = '@SYSTEM_OTHER@'
export default {
  data () {
    return {
      COMPONENT_TYPES,
      SYSTEM_OTHER,
      model: {
        template: []
      },
      rules: {}
    }
  },
  props: {
    templateConfig: {
      type: String,
      default: ''
    }
  },
  watch: {
    templateConfig: {
      immediate: true,
      handler (config) {
        config = config ? JSON.parse(config) : []
        this.model.template = config
        this.rules = config.reduce((acc, cur, index) => {
          acc[`template.${index}.value`] = [
            { required: cur.isRequired, message: this.$t('common.required') },
            { validator: this.validator(cur) }
          ]
          return acc
        }, {})
      }
    }
  },
  computed: {
    lang () {
      const userInfo = localStorage.getItem('userInfo')
      let { language } = JSON.parse(userInfo)
      return language
    }
  },
  methods: {
    textToHtml (str) {
      const replaceMap = {
        // 换行转br
        '↵': '<br/>',
        '\r\n': '<br/>',
        '\n': '<br/>'
        // ' ': '&nbsp;'
      }

      return str.replace(/(↵|\r\n|\n)/ig, function (all, t) {
        return replaceMap[t]
      })
    },
    validator (cur) {
      return (rule, value, callBack) => {
        if (!cur.isRequired) callBack()
        if (value === SYSTEM_OTHER) {
          if (cur.otherValue) callBack()
          callBack(new Error(this.$t('common.required')))
        } else {
          if (value) callBack()
          callBack(new Error(this.$t('common.required')))
        }
      }
    },
    getLabel (item, isDisplay) { // 此处需要区分系统添加的other项与用户手动填写的other项
      let map = isDisplay ? {
        en: item.labelEn,
        cn: item.labelCn
      } : {
        en: item.isOther ? SYSTEM_OTHER : item.labelEn,
        cn: item.isOther ? SYSTEM_OTHER : item.labelCn
      }
      return map[this.lang]
    },
    replaceUrlToLink (str) {
      return str.replace(/https?:\/\/(([a-zA-Z0-9_-])+(\.)?)*(:\d+)?(\/((\.)?(\?)?=?&?[a-zA-Z0-9_-](\?)?)*)*/g, function (match) {
        return `<a target="_blank" href="${match}">${match}</a>`
      })
    },
    getData () {
      let _data = this.model.template.map(item => ({
        ...item,
        value: item.otherValue || item.value
      }))
      console.info('动态表单数据：', _data)
      return JSON.stringify(_data)
    },
    validate (cb) {
      this.$refs.form.validate(result => {
        if (!result) return
        cb(result)
      })
    }
  }
}
</script>

<style scoped lang="scss">
.c--generate-form{
  /deep/ .ez-radio-group{
    display: block;
    line-height: normal!important;
    .ez-radio__label{
      display: inline-block;
      max-width: 460px;
      white-space: normal;
    }
    .ez-radio{
      display: block;
      user-select: auto;
    }
    .ez-radio__input{
      vertical-align: top;
      margin-top: 7px;
    }
    &.is-line2 {
      .ez-radio{
        display: inline-block;
        min-width: 50%;
        margin-right: 0;
        padding-right: 30px;
        user-select: auto;
      }
    }
  }
  /deep/ .ez-checkbox-group{
    display: block;
    line-height: normal!important;
    .ez-checkbox{
      display: block;
      user-select: auto;
    }
    .ez-checkbox__label{
      display: inline-block;
      max-width: 460px;
      white-space: normal;
    }
    .ez-checkbox__input{
      vertical-align: top;
      margin-top: 7px;
    }
    &.is-line2 {
      .ez-checkbox{
        display: inline-block;
        min-width: 50%;
        margin-right: 0;
        padding-right: 30px;
        user-select: auto;
      }
    }
  }
  .text-wrap {
    padding: 4px 0;
    line-height: 22px;
  }
}
</style>
