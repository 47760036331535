<template>
  <div class="c--attachment-upload">
    <ez-upload
      :before-upload="beforeUpload"
      :on-success="onUploadSuccess"
      :on-error="onUploadError"
      :on-preview="onPreview"
      :before-remove="onRemove"
      :file-list="fileList"
      :limit="MAX_NUM"
      :on-exceed="onExceed"
      drag
      :action="qiniuInfo.action"
      :data="qiniuInfo"
      :show-file-list="false"
      multiple
    >
    </ez-upload>

    <section class="file-list">
      <div v-for="item in fileList" :key="item.uid">
        <FileName
          :file-name="item.name"
          :file-url="getFileUrl(item)"
          close
          @close="handleDelete(item)"
        />
      </div>
    </section>
  </div>
</template>

<script>
import { getFileUrl } from '@/utils/utils'
const MAX_SIZE = 50
const MAX_NUM = 9
export default {
  data () {
    return {
      MAX_NUM,
      MAX_SIZE,
      qiniuInfo: {},
      fileList: []
    }
  },
  methods: {
    beforeUpload (file) {
      if (file.size > MAX_SIZE * 1024 * 1024) {
        if (!this._warningTag) {
          this.$message.warning(this.$t('common.fileMaxSizeTip', { num: MAX_SIZE }))
          this._warningTag = true
        }
        setTimeout(() => {
          this._warningTag = false
        })
        return false
      }
      this.$emit('input', true)
      return new Promise((resolve, reject) => {
        this.$axios.get('/api/wms-platform/qny/getUploadInfo', {
          params: { applicationType: 'ticket' }
        }).then(res => {
          this.qiniuInfo = res.data
          this.$nextTick(() => {
            resolve()
          })
        }).catch(error => {
          console.error(error)
          reject(error)
        })
      })
    },
    getFileUrl (item) {
      const url = item.response ? item.response.data.url : ''
      return getFileUrl(url, item.name)
    },
    onUploadSuccess (response, file, fileList) {
      this.fileList = fileList
      this.$emit('input', false)
    },
    onUploadError () {
      this.$emit('input', false)
    },
    onRemove (file, fileList) {
      this.fileList = fileList
    },
    onPreview ({ response }) {
      window.open(response.data.url, '_blank')
    },
    onExceed () {
      this.$message.warning(this.$t('common.fileNumExceedTip', { num: MAX_NUM }))
    },
    handleDelete (file) {
      this.fileList = this.fileList.filter(item => item.uid !== file.uid)
    }
  }
}
</script>

<style scoped lang="scss">
/deep/ {
  .file-size{
    display: none;
  }
  .file-name{
    cursor: pointer;
  }
}
</style>
