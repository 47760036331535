<template>
  <main class="p--ticket-create">
    <ez-form ref="form" class="outer-form" :model="form" :rules="rules" inline>
      <ez-form-item :label="label('subject')" prop="subject">
        <ez-input v-model="form.subject" maxlength="50" />
      </ez-form-item>
      <ez-form-item :label="label('ticketType')" prop="ticketTemplateId">
        <ez-select v-model="form.ticketTemplateId" @change="handleChangeTicketType" placeholder=" " :disabled="ticketTemplateDisabled">
          <ez-option
            v-for="item in mixinTicketTypes"
            :key="item.value"
            :value="item.value"
            :label="item.label"
          />
        </ez-select>
      </ez-form-item>
      <GenerateForm ref="generateForm" :templateConfig="templateConfig" />
      <ez-form-item :label="label('description')">
        <ez-input
          :rows="5"
          type="textarea"
          resize="none"
          v-model="form.description"
          maxlength="500"
          show-word-limit
        />
      </ez-form-item>
      <ez-form-item :label="label('attachment')">
        <UploadAttachment ref="upload" v-model="isUploading" v-loading="isUploading" />
      </ez-form-item>
      <ez-form-item label=" ">
        <ez-button
          type="primary"
          :loading="isSubmitLoading"
          :disabled="isUploading"
          @click="handleSubmit">
          {{ $t('common.submit') }}
        </ez-button>
      </ez-form-item>
    </ez-form>
  </main>
</template>

<script>
import GenerateForm from './GenerateForm.vue'
import UploadAttachment from './UploadAttachment'
import mixin from '../mixin'
import { BUSINESSES } from '@/config'
export default {
  mixins: [mixin],
  components: {
    GenerateForm,
    UploadAttachment
  },
  data () {
    return {
      isSubmitLoading: false,
      isUploading: false,
      ticketTemplateDisabled: false,
      templateConfig: '',
      form: {
        subject: null,
        description: null,
        ticketTemplateId: null,
        ticketTypeCn: null,
        ticketTypeEn: null
      },
      types: [

      ]
    }
  },
  computed: {
    rules () {
      return {
        subject: [
          { required: true, message: this.$t('common.required') }
        ],
        ticketTemplateId: [
          { required: true, message: this.$t('common.required') }
        ]
      }
    }
  },
  watch: {
    mixinTicketTypes: {
      handler (val) {
        const { ticketTypeEn, templateField } = this.$route.query
        if (ticketTypeEn) {
          const cur = this.mixinTicketTypes.find(item => item.ticketTypeEn === ticketTypeEn)
          if (cur) {
            this.form.ticketTemplateId = cur.id
            this.ticketTemplateDisabled = true
            const configureField = JSON.parse(cur.configureField)
            if (templateField) {
              const preConfigureField = JSON.parse(templateField)
              configureField.forEach(item => {
                const preItem = preConfigureField.find(p => p.labelEn === item.labelEn)
                if (preItem) {
                  item.disabled = preItem.disabled
                  item.value = preItem.value
                  item.otherValue = preItem.otherValue
                }
              })
            }
            this.templateConfig = JSON.stringify(configureField)
            this.form.ticketTypeCn = cur.ticketTypeCn
            this.form.ticketTypeEn = cur.ticketTypeEn
          }
        }
      }
    }
  },
  mounted () {
    this.mixinGetTicketTypes()
  },
  methods: {
    handleChangeTicketType (val) {
      const cur = this.mixinTicketTypes.find(item => item.id === val)
      if (cur) {
        this.templateConfig = cur.configureField
        this.form.ticketTypeCn = cur.ticketTypeCn
        this.form.ticketTypeEn = cur.ticketTypeEn
      }
    },
    label (lang) {
      return this.$t(`common.${lang}`) + ': '
    },
    getParams () {
      const submittingAttachments = this.$refs.upload.fileList.map(item => ({
        fileId: item.response.data.fileId,
        fileUrl: item.response.data.url,
        fileName: item.name
      }))
      const generateFormData = this.$refs.generateForm.getData()
      return {
        platformSource: this.$route.params.source === BUSINESSES.SHIPA2Z.VALUE ? BUSINESSES.SHIPOUT.VALUE : this.$route.params.source,
        submittingAttachments,
        ...this.form,
        configureFieldContent: generateFormData
      }
    },
    handleSubmit () {
      this.$refs.form.validate(result => {
        if (!result) return
        this.$refs.generateForm.validate(() => {
          this.isSubmitLoading = true
          this.$axios.post('/api/shipout-workorder/ticketcase/submit', this.getParams())
            .then(res => {
              this.isSubmitLoading = false
              this.$message.success(this.$t('common.successTip'))
              this.$router.push({ name: 'ticket-list' })
            }).catch(error => {
              this.isSubmitLoading = false
              console.error(error)
            })
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">
.p--ticket-create{
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  /deep/ .ez-form-item{
    display: flex;
    .ez-form-item__label{
      width: 200px!important;
    }
    .ez-form-item__content{
      flex: 1;
      width: 0
    }
    .ez-checkbox-group, .ez-radio-group{
      line-height: 22px;
    }
  }
  .outer-form{
    flex: 1;
    padding: 30px 0;
    width: 700px;
    margin: 0 auto;
  }
  /deep/ .ez-input, .ez-textarea{
    width: 340px;
  }
  /deep/ .ez-upload-dragger{
    width: 340px;
  }
}
</style>
